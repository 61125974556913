import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Card, Spinner, ListGroup, Form, Row, Col } from "react-bootstrap"
import { usePartner } from "@src/partner"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { getDevice, sendEmailViaMailgun } from "@src/api"
import { colors, config } from "@src/constants"
import { InputField, ModalView } from "@src/components"
import { useAuthentication } from "@src/auth"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"
import { StyleSheet } from "@src/types"

const RADIO_OPTIONS = [
  { name: "remove", labelKey: "settings.partner.remove" },
  { name: "transfer", labelKey: "settings.partner.transfer" },
]

const styles: StyleSheet = {
  listGroup: {
    overflowY: "scroll",
    flex: 1,
  },
  divBox: {
    display: "flex",
    flexDirection: "column",
    height: "350px",
    marginBottom: "20px",
  },
  warningCard: { backgroundColor: colors.whiteGray },
  toggleButton: { marginTop: "10px" },
}

export const ManageTrackers: React.FC = () => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const { userEmail } = useAuthentication()

  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [apiLoader, setApiLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [checked, setChecked] = useState(false)
  const [results, setResults] = useState<SLIM_DEVICE_TYPE[]>([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SLIM_DEVICE_TYPE[]>([])
  const [radio, setRadio] = useState(RADIO_OPTIONS[0].name)
  const [fleetName, setFleetName] = useState("")

  useEffect(() => {
    if (partner) {
      setLoading(true)
      getDevice(partner?.uuid, null, { search_string: searchText, per_page: config.itemsPerPage })
        .then(({ response, data }) => {
          if (response.ok) setResults(data.devices)
        })
        .finally(() => setLoading(false))
    }
  }, [searchText, partner])

  const handleCheckbox = (device: SLIM_DEVICE_TYPE) => {
    setSelectedCheckboxes((prev) =>
      prev.some((ch) => ch.serialnumber === device.serialnumber)
        ? prev.filter((ch) => ch.serialnumber !== device.serialnumber)
        : [...prev, device],
    )
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const sendEmailToCustomerCase = async () => {
    setShowModal(false)
    setApiLoader(true)

    const deviceIds = selectedCheckboxes.map((ch) => ch.serialnumber).join("; ")
    const baseMessage = `This is a generated message from the fleet portal: http://dashboard.iot-venture.com\n\n“${
      partner.name
    }” (${partner.uuid}) (${userEmail}) has requested to ${
      radio === "remove" ? "remove" : `transfer the following trackers to “${fleetName}”`
    }: ${deviceIds}.{{REQUIRE_QR_MSG_PLACEHOLDER}}\n\nPlease follow the link, create a group and copy the serial numbers into the group:\nhttps://itsmybike.cloud/groups/new`

    const qrMessage = `\n\nFor these trackers, additional QR codes are required for use in the IT'S MY BIKE app. Please send them to ${userEmail}.`
    const message = baseMessage.replace("{{REQUIRE_QR_MSG_PLACEHOLDER}}", checked ? qrMessage : "")

    const response = await sendEmailViaMailgun({
      from: "fleetportal@itsmybike.com",
      subject: `Request for ${radio === "remove" ? "Removal" : "Transfer"} of Selected Trackers from ${
        partner.name
      }'s Fleet`,
      text: message,
    })

    if (response.message) {
      resetForm()
    }
  }

  const resetForm = () => {
    setSelectedCheckboxes([])
    setApiLoader(false)
    setShowCard(true)
    setChecked(false)
    setFleetName("")
  }

  const onInputPersist = (field: string) => (text: string) => {
    setFleetName(text)
  }

  return (
    <div>
      {showCard && (
        <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
          {t("settings.partner.mail_toast")}
        </Card>
      )}

      <Form.Control
        type="search"
        id="search"
        autoComplete="off"
        placeholder={t("sidebar.search_placeholder")}
        value={searchText}
        onChange={onSearchChange}
      />
      <div style={styles.divBox}>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <ListGroup className="mt-2" style={styles.listGroup}>
            {results.map((result) => (
              <ListGroup.Item key={result.serialnumber} onClick={() => handleCheckbox(result)}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={selectedCheckboxes.some((ch) => ch.serialnumber === result.serialnumber)}
                    onChange={() => {}}
                  />
                  <label className="ms-2">
                    {result.serialnumber} - {result.name}
                  </label>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>

      <ButtonGroup>
        {RADIO_OPTIONS.map((item, idx) => (
          <ToggleButton
            key={item.name}
            id={`radio-${idx}`}
            type="radio"
            variant="outline-primary"
            value={item.name}
            checked={radio === item.name}
            onChange={(e) => setRadio(e.currentTarget.value)}>
            {item.name === "remove" ? t("settings.partner.remove") : t("settings.partner.transfer")}
          </ToggleButton>
        ))}
      </ButtonGroup>

      <div style={styles.toggleButton}>
        {radio === "remove" ? (
          <div className="d-inline-flex flex-row">
            <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />
            <label className="ms-2">{t("settings.partner.pop_up_checkbox", { value: userEmail })}</label>
          </div>
        ) : (
          <Row>
            <div className="d-flex mt-1 mb-2">{t("settings.partner.fleet_name")}</div>

            <Col xs={8} md={6} lg={4}>
              <InputField
                label=""
                value={fleetName}
                onChangeText={onInputPersist("name")}
                onPersist={onInputPersist("name")}
                loading={false}
              />
            </Col>
          </Row>
        )}
      </div>

      <div className="mt-3">
        <Button
          disabled={selectedCheckboxes.length === 0 || (radio === "transfer" && fleetName === "")}
          className="btn btn-primary-outline"
          onClick={() => setShowModal(true)}>
          {radio === "remove" ? t("settings.partner.remove_trackers") : t("settings.partner.transfer_trackers")}
          {apiLoader && <Spinner animation="border" size="sm" className="ms-2" />}
        </Button>
      </div>

      <ModalView
        title={t("settings.partner.alert")}
        buttonLoader={false}
        buttonTitle={
          radio === "remove" ? t("settings.partner.remove_trackers") : t("settings.partner.transfer_trackers")
        }
        show={showModal}
        onHideModal={() => setShowModal(false)}
        onButtonClick={sendEmailToCustomerCase}>
        <label>
          {radio === "remove" ? t("settings.partner.alert_to_remove") : t("settings.partner.alert_to_transfer")}
        </label>
      </ModalView>
    </div>
  )
}
